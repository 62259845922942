import { Route, Routes } from 'react-router-dom';

import MainOutlet from '../Pages/Main/MainOutlet';
import DefaultOutlet from '../Pages/DefaultOutlet';
import PrivateRoute from './PrivateRoute';
import LoginRedirectPage from '../Pages/Main/LoginRedirect/LoginRedirectPage';
import LoginRedirectRatingsPage from '../Pages/Main/LoginRedirect/LoginRedirectRatingsPage';
import ChatPage from '../Pages/Main/Chat/ChatPage';
import LoginPage from '../Pages/Auth/Login/LoginPage';
import ContactPage from '../Pages/Contact/ContactPage';
import ErrorPage from '../Pages/Error/ErrorPage';
import EmailVerifyPage from '../Pages/EmailVerify/EmailVerify';
import HelpPage from '../Pages/Help/HelpPage';
import ForgotPasswordPage from '../Pages/Auth/ForgotPassword/ForgotPasswordPage';
import RedirectResetPasswordPage from '../Pages/Auth/ForgotPassword/RedirectResetPasswordPage';
import SetNewPasswordPage from '../Pages/Auth/ForgotPassword/SetNewPasswordPage';
import LoadSchedulerPage from '../Pages/LoadScheduler/LoadSchedulerPage';
import RedirectIfLoggedIn from './RedirectIfLoggedIn';
import UserPage from '../Pages/User/UserPage';
import { IdentityForm } from '../Components/Form/UserProfile/IdentityForm';
import { CompanyForm } from '../Components/Form/UserProfile/CompanyForm';
import { ProviderForm } from '../Components/Form/UserProfile/ProviderForm';

const Router = () => {
  return (
    <Routes>
      {/* Outer Route 1: MainOutlet for authorized users */}
      <Route path="/" element={<MainOutlet />}>
        <Route index element={<PrivateRoute component={ChatPage} />} />
        <Route
          path="scheduler"
          element={<PrivateRoute component={LoadSchedulerPage} />}
        />

        {/* User Route with Sub-Routes */}
        <Route path="user" element={<PrivateRoute component={UserPage} />}>
          {/* Default form, user redirects to Identity */}
          <Route index element={<IdentityForm />} />
          <Route path="company" element={<CompanyForm />} />
          <Route path="provider" element={<ProviderForm />} />
        </Route>
      </Route>
      {/* Outer Route 2: DefaultOutlet for unauthenticated users */}
      <Route path="/" element={<DefaultOutlet />}>
        <Route
          path="copilot/oauth/callback/123loadboard"
          element={<LoginRedirectPage />}
        />
        <Route
          path="auth"
          element={<RedirectIfLoggedIn component={LoginPage} />}
        />
        {/*Forgot Password Pages*/}
        <Route path="auth/forgot-password" element={<ForgotPasswordPage />} />
        <Route
          path="auth/redirect-reset-password/:uidb64/:token"
          element={<RedirectResetPasswordPage />}
        />
        <Route
          path="auth/set-password/:uidb64/:token"
          element={<SetNewPasswordPage />}
        />

        <Route path="contact" element={<ContactPage />} />
        <Route path="email-verification/:token" element={<EmailVerifyPage />} />
        <Route
          path="copilot/oauth/callback/truckstop"
          element={<LoginRedirectRatingsPage />}
        />
        <Route path="error" element={<ErrorPage />} />

        <Route path="help" element={<HelpPage />} />
      </Route>
      <Route path="*" element={<ErrorPage notfound />} />
    </Routes>
  );
};

export default Router;
