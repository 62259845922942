import { useEffect, useState } from 'react';

import { UserProfileInputGroup } from './UserProfileInputGroup';
import { UserProfileSelectGroup } from './UserProfileSelectGroup';
import UserProfileCard from '../../UserProfileCard/UserProfileCard';
import { defautUserValues } from './defaultUserValues';
import { IUser, IUserProfileUpdate } from '../../../Models/UserModel';
import {
  useGetUserProfile,
  usePatchUserProfile,
} from '../../../Hooks/useApi/useAuthenticationApi';
import { CustomCircularLoading } from '../../CustomCircularLoading/CustomCircularLoading';
import * as S from './userProfileForms.styled';
import { useNavigate } from 'react-router-dom';

export const CompanyForm = () => {
  const { userProfileData, userProfileDataIsLoading, userProfileError } =
    useGetUserProfile();
  const { updateUserProfile, isLoadingProfileUpdate, isUpdateError } =
    usePatchUserProfile();
  const navigate = useNavigate();
  const [{ company_name, mc_number, dot_number, is_broker }, setFormValues] =
    useState<IUser>(defautUserValues);
  // Sync state with userData once it arrives
  useEffect(() => {
    if (userProfileData) {
      setFormValues(userProfileData);
    }
  }, [userProfileData]);

  // Update formValues on input change
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Update `is_broker` based on new select field
  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setFormValues((prev) => ({
      ...prev,
      is_broker: value === 'Broker',
    }));
  };
  // console.log('company_name:', company_name);
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const companyPayload = {
      ...userProfileData,
      company_name,
      mc_number,
      dot_number,
      is_broker,
    };
    console.log('companyPayload', companyPayload);
    updateUserProfile(companyPayload as IUserProfileUpdate);
  };

  if (userProfileDataIsLoading || isLoadingProfileUpdate) {
    return (
      <UserProfileCard>
        <CustomCircularLoading />;
      </UserProfileCard>
    );
  }

  return (
    <S.UserProfileFormContainer onSubmit={handleSubmit}>
      <UserProfileCard>
        {userProfileError && (
          <S.UserProfileErrorSpan>
            Error fetching user profile data
          </S.UserProfileErrorSpan>
        )}
        {isUpdateError && (
          <S.UserProfileErrorSpan>
            Error updating user profile data
          </S.UserProfileErrorSpan>
        )}
        <UserProfileInputGroup
          label="Company"
          name="company_name"
          value={company_name || ''}
          handleInputChange={handleInputChange}
        />
        <UserProfileInputGroup
          label="MC #"
          name="mc_number"
          value={mc_number || ''}
          handleInputChange={handleInputChange}
        />
        <UserProfileInputGroup
          label="DOT #"
          name="dot_number"
          value={dot_number || ''}
          handleInputChange={handleInputChange}
        />
        <UserProfileSelectGroup
          label="User Type"
          name="is_broker"
          options={['Broker', 'Carrier']}
          value={is_broker ? 'Broker' : 'Carrier'}
          handleSelectChange={handleSelectChange}
        />
        <S.UserProfileFormFooter>
          <S.UserProfileReturnButton onClick={() => navigate('/')}>
            Back to ChatPage
          </S.UserProfileReturnButton>
          <S.UserProfileButton type="submit">Save</S.UserProfileButton>
        </S.UserProfileFormFooter>
      </UserProfileCard>
    </S.UserProfileFormContainer>
  );
};
