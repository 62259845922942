import { MessageItem } from '../../../Components/Recycler/MessageRecycler';
import { HelpBoxType } from '../../../App/Types/helpBox';
import MessageRecycler from '../../../Components/Recycler/MessageRecycler';
import GreetingSection from './GreetingsSection';

interface ChatPageContentProps {
  updatedMessages: MessageItem[];
  handleTruckTypeSelect: (truckType: string) => void;
  onSendMessageClickListener: (query?: string) => Promise<void>;
  helpList: HelpBoxType[];
  onHelpBoxClickListener: (query: string) => Promise<void>;
}

const ChatPageContent: React.FC<ChatPageContentProps> = ({
  updatedMessages,
  handleTruckTypeSelect,
  onSendMessageClickListener,
  helpList,
  onHelpBoxClickListener,
}: ChatPageContentProps) => {
  return updatedMessages.length ? (
    <MessageRecycler
      messages={updatedMessages}
      handleSelect={handleTruckTypeSelect}
      sendNewMessage={onSendMessageClickListener}
    />
  ) : (
    <GreetingSection
      helpList={helpList}
      onHelpBoxClickListener={onHelpBoxClickListener}
    />
  );
};

export default ChatPageContent;
