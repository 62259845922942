import { NavLink } from 'react-router-dom';
import { useState } from 'react';
import styled from 'styled-components';

import { sizes } from '../../Utils/screenUtil';

const UserSideBar = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const handleDropdownToggle = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  return (
    <UserSideBarWrapper>
      <SideBarContainer>
        <SideBarLink to="/user" end>
          Identity
        </SideBarLink>
        <SideBarLink to="/user/company">Company Details</SideBarLink>
        <SideBarLink to="/user/provider">Provider Details</SideBarLink>
      </SideBarContainer>

      <DropdownWrapper>
        <DropdownButton onClick={handleDropdownToggle}>
          {isDropdownOpen ? 'Close Menu' : 'Open Menu'}
        </DropdownButton>
        {isDropdownOpen && <button>test</button>}
      </DropdownWrapper>
    </UserSideBarWrapper>
  );
};

export default UserSideBar;

const UserSideBarWrapper = styled.div`
  width: 250;
  @media only screen and (max-width: ${sizes.laptop}) {
    display: none;
  }
`;

const SideBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-image: ${({ theme }) =>
    `linear-gradient(to top left, ${theme.darkBackground}, ${theme.primary})`};
  padding: 1rem;
  width: 250px;
  border-radius: 10px;
  border: 1px solid #c7c7c747;
`;

const SideBarLink = styled(NavLink)`
  margin: 4px 0 4px 0;
  padding: 0.8rem;
  border-radius: 5px;
  cursor: pointer;
  color: ${({ theme }) => theme.detailsText};
  text-decoration: none;

  &.active {
    background-color: ${({ theme }) => theme.hover};
    border: ${({ theme }) => `1px solid ${theme.userProfileActiveBorder};`};
  }

  &:hover {
    background-color: ${({ theme }) => theme.hover};
  }
`;

const DropdownWrapper = styled.div`
  display: none;

  /* Show on smaller screens */
  @media only screen and (max-width: ${sizes.tablet}) {
    display: block;
    position: relative;
    width: 100%;
    padding: 1rem;
  }
`;

const DropdownButton = styled.button`
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
`;
