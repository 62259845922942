import useAxios from "../useAxios/useAxios"
import { SEND_EMAIL_ENDPOINT } from "../../App/Axios/endpoints"

interface SendEmailParams {
  firstName: string,
  lastName: string,
  message: string,
  email: string,
  company?: string | null,
  dotNumber?: string | null
}

const useEmail = () => {

  const axios = useAxios();

  const sendEmail = async ({ firstName, lastName, message, email, company, dotNumber }: SendEmailParams): Promise<string> => {
    try {
      const emailSubject = `Message from ${firstName} ${lastName}`;
      const emailMessage = `${message}

        Company: ${company}
        DOT Number: ${dotNumber}

        ${firstName} ${lastName}
      `;

      const data = {
        receiver: email,
        subject: emailSubject,
        text: emailMessage
      };

      const res = await axios.post(SEND_EMAIL_ENDPOINT, data);

      if (res.status === 200) {
        return '';
      }
      return res.data.message[0].msg || 'Some error occured with the request';

    } catch (err: any) {
      console.log(err);
      return err.response.data.message[0].msg;
    }
  }

  return { sendEmail };
}

export default useEmail;

