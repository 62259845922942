import styled from 'styled-components';
import MuiModal from '@mui/material/Modal';
import { Close } from '@styled-icons/material';
import { useState } from 'react';

interface BaseModalProps {
  title: string;
  subtitle: string;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit?: (data: string) => void;
  hasInput?: boolean;
  currentInputValue?: string;
}

export const BaseModal: React.FC<BaseModalProps> = ({
  title,
  subtitle,
  isOpen,
  setIsOpen,
  handleSubmit,
  hasInput = false,
  currentInputValue = '',
}) => {
  const [inputText, setInputText] = useState<string>(currentInputValue);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputText(e.target.value);
  };

  const handleClose = () => {
    setIsOpen(false);
    setInputText(''); // Clear input on close
  };

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSubmit && handleSubmit(inputText);
    handleClose();
  };

  return (
    <MuiModal open={isOpen} onClose={handleClose} closeAfterTransition>
      <TransparentModal onClick={handleClose}>
        <FormContainer
          onClick={(e) => e.stopPropagation()} // Prevent modal from closing when clicking inside
          onSubmit={handleFormSubmit}
        >
          <ModalHeader>
            <ModalTitle>{title}</ModalTitle>
            <CloseIcon size={35} onClick={handleClose} />
          </ModalHeader>
          <FormBody>
            <Text>{subtitle}</Text>
            {hasInput && (
              <>
                <Input
                  value={inputText}
                  onChange={handleInputChange}
                  placeholder="Enter your input"
                />
              </>
            )}
          </FormBody>
          <ButtonContainer>
            <SubmitButton type="submit" disabled={inputText.trim() === ''}>
              Submit
            </SubmitButton>
          </ButtonContainer>
        </FormContainer>
      </TransparentModal>
    </MuiModal>
  );
};

// Styles

const TransparentModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  outline: none;
`;

const FormContainer = styled.form`
  width: 350px;
  border-radius: 10px;
  padding: 20px;
  background-color: ${({ theme }) => theme.secondary};
  border: 1px solid ${({ theme }) => theme.settingsBorderOutlineColor};
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
`;

const ModalHeader = styled.div`
  position: relative;
  display: flex;
`;

const ModalTitle = styled.h2`
  color: ${({ theme }) => theme.title};
  font-size: 15px;
`;

const CloseIcon = styled(Close)`
  color: ${({ theme }) => theme.alternativeWhite};
  position: absolute;
  right: -10px;
  top: -10px;
  cursor: pointer;
  height: 30px;
`;

const FormBody = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  flex-grow: 1;
`;

const Text = styled.p`
  color: ${({ theme }) => theme.detailsText};
  font-size: 14px;
  margin-bottom: 10px;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.settingsBorderColor};
  border-radius: 5px;
  font-size: 16px;
  margin-bottom: 20px;
  color: black;
  background-color: lightgray;
  &:focus {
    outline: 1px solid lightGray;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const SubmitButton = styled.button<{ disabled: boolean }>`
  padding: 10px;
  width: 150px;
  background-color: ${({ theme, disabled }) =>
    disabled ? 'gray' : theme.schedulerButtonBg};
  color: ${({ theme }) => theme.buttonText};
  border: none;
  border-radius: 5px;
  font-size: 16px;
  transition: background-color 0.3s ease;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  &:hover {
    background-color: ${({ theme, disabled }) =>
      disabled ? '' : theme.schedulerHoverBg};
  }
`;
