/* eslint-disable react-hooks/exhaustive-deps */
import styled from 'styled-components';
import MuiModal from '@mui/material/Modal';
import Skeleton from '@mui/material/Skeleton';
import { Close } from '@styled-icons/material';
import { useEffect, useState } from 'react';

import useChatApi from '../../Hooks/useApi/useChatApi';

interface Provider {
  id: string;
  name: string;
}

export const ManifoldProvidersModal: React.FC<{
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ isOpen, setIsOpen }) => {
  const { getManifoldProviders } = useChatApi();
  const [providers, setProviders] = useState<Provider[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!isOpen) return;

    const fetchProviders = async () => {
      setLoading(true);
      try {
        const response = await getManifoldProviders();
        if (response.data.success) {
          setProviders(response.data.data);
          setError(null);
        } else {
          setError('Failed to fetch providers.');
        }
      } catch (err) {
        console.error('Failed to fetch providers:', err);
        setError('Error getting the providers. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchProviders();
  }, [isOpen]);

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <MuiModal open={isOpen} closeAfterTransition>
      <TransparentModal onClick={handleClose}>
        <ModalContent onClick={(e) => e.stopPropagation()}>
          <ModalHeader>
            <ModalTitle>Broker Providers</ModalTitle>
            <CloseIcon size={25} onClick={handleClose} />
          </ModalHeader>
          {error && <ErrorMessage>{error}</ErrorMessage>}
          <ProviderList>
            {loading
              ? Array.from({ length: providers.length || 7 }).map(
                  (_, index) => <SkeletonProviderCard key={index} />
                )
              : providers.map((provider) => (
                  <ProviderCard key={provider.id}>{provider.name}</ProviderCard>
                ))}
          </ProviderList>
        </ModalContent>
      </TransparentModal>
    </MuiModal>
  );
};

const TransparentModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  outline: none;
`;

const ModalContent = styled.div`
  width: 350px;
  min-height: 200px;
  max-height: 500px;
  border-radius: 10px;
  padding: 20px;
  background-color: ${({ theme }) => theme.secondary};
  border: 1px solid ${({ theme }) => theme.settingsBorderOutlineColor};
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
`;

const ModalHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

const ModalTitle = styled.h2`
  color: ${({ theme }) => theme.title};
  font-size: 18px;
`;

const CloseIcon = styled(Close)`
  color: ${({ theme }) => theme.alternativeWhite};
  position: absolute;
  right: -10px;
  top: -10px;
  cursor: pointer;
`;

const ErrorMessage = styled.span`
  color: red;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
`;

const ProviderList = styled.div`
  margin-top: 20px;
  max-height: 400px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 20px 10px;
`;

const ProviderCard = styled.div`
  padding: 10px;
  color: ${({ theme }) => theme.detailsText};
  border: 1px solid ${({ theme }) => theme.settingsBorderColor};
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;
`;

const SkeletonProviderCard = styled(Skeleton).attrs({
  variant: 'rectangular',
  width: '100%',
  height: '40px',
})`
  border-radius: 5px;
  background-color: #111111 !important;
`;

export default ManifoldProvidersModal;
