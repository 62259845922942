export const defautUserValues = {
  first_name: '',
  last_name: '',
  email: '',
  phone_number: '',
  created_at: '',
  ts_id: '',
  is_broker: false,
  mc_number: '',
  dot_number: '',
  manifold_id: '',
  company_name: '',
};
