import { useEffect, useState } from 'react';

import UserProfileCard from '../../UserProfileCard/UserProfileCard';
import { UserProfileInputGroup } from './UserProfileInputGroup';
import {
  useGetUserProfile,
  usePatchUserProfile,
} from '../../../Hooks/useApi/useAuthenticationApi';
import { IUser } from '../../../Models/UserModel';
import { defautUserValues } from './defaultUserValues';
import { CustomCircularLoading } from '../../CustomCircularLoading/CustomCircularLoading';
import * as S from './userProfileForms.styled';
import { useNavigate } from 'react-router-dom';

export const ProviderForm = () => {
  const { userProfileData, userProfileDataIsLoading, userProfileError } =
    useGetUserProfile();
  const navigate = useNavigate();
  const { isLoadingProfileUpdate, isUpdateError } = usePatchUserProfile();

  const [{ ts_id }, setFormValues] = useState<IUser>(defautUserValues);

  // Sync state with userData once it arrives
  useEffect(() => {
    if (userProfileData) {
      setFormValues(userProfileData);
    }
  }, [userProfileData]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    // e.preventDefault();
    // const providerPayload = {
    //   ...userProfileData,
    //   ts_id,
    // };
    // updateUserProfile(providerPayload as IUserProfileUpdate);
  };

  if (userProfileDataIsLoading || isLoadingProfileUpdate) {
    return (
      <UserProfileCard>
        <CustomCircularLoading />;
      </UserProfileCard>
    );
  }

  return (
    <S.UserProfileFormContainer onSubmit={handleSubmit}>
      <UserProfileCard>
        {userProfileError && (
          <S.UserProfileErrorSpan>
            Error fetching user profile data
          </S.UserProfileErrorSpan>
        )}
        {isUpdateError && (
          <S.UserProfileErrorSpan>
            Error fetching user profile data
          </S.UserProfileErrorSpan>
        )}
        <UserProfileInputGroup
          label="Truckstop Integration ID"
          name="ts_id"
          value={ts_id || ''}
          handleInputChange={handleInputChange}
          disabled
        />
        <S.UserProfileFormFooter>
          <S.UserProfileReturnButton onClick={() => navigate('/')}>
            Back to ChatPage
          </S.UserProfileReturnButton>
          <S.UserProfileButton type="submit" disabled>
            Save
          </S.UserProfileButton>
        </S.UserProfileFormFooter>
      </UserProfileCard>
    </S.UserProfileFormContainer>
  );
};
