import styled from 'styled-components';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import { PluginCard } from '../../Components/PluginCard/PluginCard';
import { useContext, useState } from 'react';
import { PluginContext } from '../../Context/Plugin/PluginContext';
import truckStopIco from '../../Assets/truckStop.png';
import loadboardPng from '../../Assets/123loadboard.png';
import useChatApi from '../../Hooks/useApi/useChatApi';
import { get123LoadboardLoginURL } from '../../Utils/providerHelpers';

export const RightBarProviders = () => {
  const [enablePopup, setEnablePopup] = useState<boolean>(false);
  const pluginContext = useContext(PluginContext);
  const { loginTruckstop } = useChatApi();

  const tsIsActive = pluginContext!.truckstopPlugin;
  const lb123IsActive = pluginContext!.LB123Plugin;
  const manifoldIsActive = pluginContext!.manifoldPlugin;

  const handleTruckstopSubmit = async (data: string) => {
    const res = await loginTruckstop(data);
    console.log('loginTruckstop res:', res);
    if (res !== null && res !== undefined) {
      if (res.success) {
        pluginContext!.setTruckstopPlugin(true);
      }
    }
  };

  const integrationIDdata = {
    isOpen: enablePopup,
    setIsOpen: setEnablePopup,
    title: 'Enter Your Integration ID',
    subtitle: 'For more information go to https://truckstop.com',
    handleSubmit: handleTruckstopSubmit,
    hasInput: true,
  };

  return (
    <ProviderContainer>
      {/* <StyledHeader>
        <AddProviderSelectField />
      </StyledHeader> */}
      <SubHeader>
        <StyledSpan>Providers</StyledSpan>
        <DotsIcon />
      </SubHeader>
      <PluginContainer>
        <PluginCard
          pluginName="Broker Loadboards"
          isBroker
          isActive={manifoldIsActive}
        />
        <PluginCard
          pluginName="Truckstop"
          src={truckStopIco}
          isBroker={false}
          isActive={tsIsActive}
          integrationIDdata={integrationIDdata}
        />
        <PluginCard
          pluginName="123LoadBoard"
          src={loadboardPng}
          isBroker={false}
          isActive={lb123IsActive}
          generateLinkUrl={get123LoadboardLoginURL}
        />
      </PluginContainer>
    </ProviderContainer>
  );
};

const ProviderContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0px;
  width: 100%;
  max-height: 100%; /* Set a maximum height */
  height: 100%; /* Let it use available space but cap at max-height */
  background-color: ${({ theme }) => theme.sideMenuAccent};
  border-radius: 10px;
  border: 1px solid ${(props) => `${props.theme.detailsText}30`};
  overflow: hidden;
`;

const PluginContainer = styled.div`
  overflow-y: auto; /* Enable vertical scrolling for the plugins */
  overflow-x: hidden;
  padding: 0px;
  flex-grow: 1; /* Allows this section to take available space */
  ::-webkit-scrollbar {
    width: 0.75em;
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.scrollBarColor};
    &:hover {
      cursor: pointer;
   }
  }​
`;

// const StyledHeader = styled.div`
//   display: flex;
//   justify-cotent: center;
//   margin: 0 10px 15px 10px;
// `;

const SubHeader = styled.div`
  margin: 40px 10px 10px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledSpan = styled.span`
  color: ${({ theme }) => theme.detailsText};
  margin-bottom: 10px;
  font-size: 20px;
  @media only screen and (min-width: 1800px) {
    /* Styles for screens more than 1800px */
    font-size: 1.3rem !important;
  }
  @media only screen and (min-width: 2240px) {
    /* Styles for screens more than 2240px */
    font-size: 1.4rem !important;
  }
`;

const DotsIcon = styled(MoreHorizIcon)`
  color: ${({ theme }) => theme.detailsText};
  opacity: 0.1;
`;
