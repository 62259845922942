import styled from 'styled-components';

export const UserProfileFormFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 10px;
`;

export const UserProfileErrorSpan = styled.span`
  font-size: 15px;
  color: red;
  margin-bottom: 20px;
`;

export const UserProfileFormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const UserProfileButton = styled.button`
  width: 50px;
  border-radius: 10px;
  background-color: ${({ theme, disabled }) =>
    disabled ? `gray` : theme.title};
  padding: 8px 4px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  border: none;
  color: ${({ theme }) => theme.buttonText};

  &:hover {
    opacity: ${({ disabled }) => (disabled ? '1' : '.8')};
  }
`;

export const UserProfileReturnButton = styled(UserProfileButton)`
  margin-right: 20px;
  padding: 8px 12px;
  width: 140px;
`;
