import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import styled from 'styled-components';

import { TableHead, TableRow } from '@mui/material';

const CustomTableHead = () => {
  return (
    <TableHead>
      <TableRow>
        <StyledTableCell />
        <StyledTableCell align="center">Age</StyledTableCell>
        <StyledTableCell align="center">Origin</StyledTableCell>
        <StyledTableCell align="center">Destination</StyledTableCell>
        <StyledTableCell align="center">Company Name</StyledTableCell>
        <StyledTableCell align="center">Weight</StyledTableCell>
        <StyledTableCell align="center">Equipment</StyledTableCell>
        <StyledTableCell align="center">Price</StyledTableCell>
        <StyledTableCell align="center">Mileage</StyledTableCell>
        <StyledTableCell align="center">Pickup</StyledTableCell>
        <StyledTableCell align="center">Provider</StyledTableCell>
      </TableRow>
    </TableHead>
  );
};

export default CustomTableHead;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    background: 'transparent',
    color: theme.text,
    borderBottom: `1px solid grey !important`,
    borderCollapse: 'collapse',
    fontSize: 14,
    padding: '12px 4px',
    width: '10%', // Ensure consistent width
  },
}));
