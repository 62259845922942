import styled from 'styled-components';
import BoltIcon from '@mui/icons-material/Bolt';
import AddIcon from '@mui/icons-material/Add';
import InfoIcon from '@mui/icons-material/Info';
import AddLinkIcon from '@mui/icons-material/AddLink';

import { Image } from '../../App/Styles/Image.styled';
import { Tooltip } from '@mui/material';
import ManifoldProvidersModal from '../Modal/ManifoldProvidersModal';
import { useState } from 'react';
import { BaseModal } from '../Modal/BaseModal';

//TODO: ADD LOGIC FOR LINK OR ADD OR INFO IF BROKER
interface PluginCardProps {
  pluginName: string;
  isBroker: boolean;
  src?: string;
  isActive: boolean;
  generateLinkUrl?: () => string;
  integrationIDdata?: {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    title: string;
    subtitle: string;
    handleSubmit: (data: string) => Promise<void>;
    hasInput: boolean;
  };
}

export const PluginCard: React.FC<PluginCardProps> = ({
  pluginName,
  src,
  isBroker,
  isActive,
  generateLinkUrl,
  integrationIDdata,
}) => {
  const [manifoldIsOpen, setManifoldIsOpen] = useState<boolean>(false);
  return (
    <PluginCardContainer
      isActive={isActive}
      isBroker={isBroker}
      onClick={() => {
        if (isBroker && isActive) {
          setManifoldIsOpen(true);
        } else if (generateLinkUrl) {
          window.open(generateLinkUrl(), '_blank', 'noopener noreferrer');
        } else if (integrationIDdata) {
          integrationIDdata.setIsOpen(true);
        }
      }}
    >
      <PluginCardWrapper>
        {isActive ? <RightBarOnlineLamp /> : <RightBarOfflineLamp />}
        {src && <ResponsiveImage src={src} width="2rem" height="2rem" />}
        {isBroker && <MuiInfoIcon />}
        {isBroker && (
          <MuiBrokerTooltip title="Powered by Manifold" placement="top">
            <MuiBoltIcon />
          </MuiBrokerTooltip>
        )}
        {integrationIDdata && <MuiAddIcon />}
        {generateLinkUrl && <MuiLinkIcon />}
        <DetailsContainer>
          <CustomNameSpan>{pluginName}</CustomNameSpan>
        </DetailsContainer>
      </PluginCardWrapper>
      <ManifoldProvidersModal
        isOpen={manifoldIsOpen}
        setIsOpen={setManifoldIsOpen}
      ></ManifoldProvidersModal>
      <BaseModal
        isOpen={integrationIDdata?.isOpen || false}
        setIsOpen={integrationIDdata?.setIsOpen || (() => {})}
        title={integrationIDdata?.title || ''}
        subtitle={integrationIDdata?.subtitle || ''}
        handleSubmit={integrationIDdata?.handleSubmit || (() => {})}
        hasInput={integrationIDdata?.hasInput || false}
      />
    </PluginCardContainer>
  );
};

const PluginCardContainer = styled.div<{
  isActive: boolean;
  isBroker: boolean;
}>`
  margin: 6px 0;
  padding: 10px 0;
  display: flex;
  background-color: ${({ theme }) => theme.pluginCardBgColor};
  box-shadow: ${({ theme }) => theme.pluginCardShadows};
  :hover {
    ${({ isActive, isBroker, theme }) =>
      !isActive && isBroker
        ? `
        cursor: not-allowed;
        transform: none;
        box-shadow:  ${theme.pluginCardShadows};
      `
        : `
        cursor: pointer;
        transform: translateY(-1px);
        box-shadow: inset 0 1px 1px rgba(255, 255, 255, 0.05),
          inset 0 -1px 1px rgba(0, 0, 0, 0.3), 0 4px 6px rgba(0, 0, 0, 0.1),
          0 1px 3px rgba(0, 0, 0, 0.08);
      `}
  }
`;

const PluginCardWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px;
  width: 100%;
  min-height: 68px;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const CustomNameSpan = styled.span`
  color: ${({ theme }) => theme.alternativeWhite};
  font-size: 1.1rem;
  @media only screen and (min-width: 2240px) {
    /* Styles for screens more than 2240px */
    font-size: 1.6rem !important;
  }
`;

const ResponsiveImage = styled(Image)`
  position: absolute; /* Take it out of the document flow */
  top: 25%;
  @media (min-width: 1320px) {
    transform: translateX(-5px);
    height: 30px;
    width: 30px;
  }
  @media (min-width: 2240px) {
    /* Styles for screens more than 2240px */
    top: 15%;
    height: 45px;
    width: 45px;
  }
`;

const MuiBoltIcon = styled(BoltIcon)`
  font-size: 18px !important;
  :hover {
    color: ${({ theme }) => theme.arrowIconHovered};
  }
  @media only screen and (min-width: 2240px) {
    /* Styles for screens less than 2240px  */
    font-size: 25px !important;
  }
`;

const MuiBrokerTooltip = styled(Tooltip)`
  position: absolute;
  top: 2px;
  left: 10px;
  color: gray;
  color: gray;
`;

const RightBarOnlineLamp = styled.div`
  position: absolute;
  right: 15px;
  padding: 0.2rem;
  border-radius: 50%;
  background-color: #5add94;
`;

const RightBarOfflineLamp = styled(RightBarOnlineLamp)`
  background-color: ${({ theme }) => theme.text};
`;

const MuiInfoIcon = styled(InfoIcon)`
  position: absolute;
  bottom: 1px;
  right: 50%;
  transform: translateX(50%);
  font-size: 12px !important;
  color: gray;
  opacity: 0.3;
`;

const MuiAddIcon = styled(AddIcon)`
  position: absolute;
  bottom: -3px;
  right: 50%;
  transform: translateX(50%);
  font-size: 20px !important;
  color: gray;
  opacity: 0.3;
`;

const MuiLinkIcon = styled(AddLinkIcon)`
  position: absolute;
  bottom: -3px;
  right: 50%;
  transform: translateX(50%);
  font-size: 20px !important;
  color: gray;
  opacity: 0.3;
`;
