import styled from 'styled-components';

import { Image } from '../../../App/Styles/Image.styled';
import { sizes } from '../../../Utils/screenUtil';

export const Container = styled.div`
  position: relative;
  height: 100%;
  margin-top: 2rem;
  width: 100%;
  max-height: 87vh;
  display: grid;
  grid-template-rows: repeat(8, auto);
`;

export const GreetingTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media (max-width: 768px) {
    grid-row: 1;
    height: 100%;
  }
`;

export const HelpBoxList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  grid-row: 6;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const QueryStickyContainer = styled.div`
  height: 4em;
  width: 100%;
  z-index: 10;
  position: absolute;
  top: 0;
  right: 0;
  background-color: ${({ theme }) => theme.secondary};
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  display: flex;
  justify-content: space-around;
  gap: 3em;
  padding: 0 2em;
  align-items: center;
`;

export const SendButton = styled.button<{ disabled: boolean }>`
  background-color: rgb(111 111 111 / 0%);
  border: none;
  display: flex;
  justify-content: center;
  padding: 1.5em; /* Increased padding for a bigger button */
  width: 4em; /* You can adjust this as needed */
  height: 4em; /* You can adjust this as needed */
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  transform: translateY(4px);
  transition: ${({ theme }) => theme.transition};

  ${Image} {
    filter: ${({ theme }) => theme.activeFilter};
  }
  @media only screen and (max-width: 1320px) {
    /* Styles for screens less than 1320px pixels */
    transform: translateY(8px);
  }
`;

export const InputContainer = styled.div`
  width: 100%;
  margin: 2rem auto 1.5rem; /* Use auto for left and right margins to center it */
  gap: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: end;
  position: relative;
  grid-row: 8;
`;

export const Input = styled.input`
  font-size: 0.9em;
  font-family: inherit;
  padding: 0.5em 0.5em;
  height: 3em;
  width: 100%;
  background-color: ${({ theme }) => theme.secondary};
  color: ${({ theme }) => theme.text};
  border: 1px solid rgba(132, 132, 132, 0.23);
  border-radius: 10px;
  overflow-y: auto;
  transition: ${({ theme }) => theme.transition};

  &:focus {
    outline: 0;
  }
`;

export const GreetingsHeader = styled.div`
  margin: 10px 0;
  font-size: 25px;
`;

export const ParagraphContainer = styled.div`
  margin: 10px 0 0 0;
  @media only screen and (max-width: ${sizes.mobileM}) {
    /* Styles for screens less than mobileM  */
    font-size: 0.85rem;
  }
`;

export const ParagraphContainer2 = styled.div`
  margin-bottom: 10px;
  @media only screen and (max-width: ${sizes.mobileM}) {
    /* Styles for screens less than mobileM  */
    font-size: 0.85rem;
  }
`;
