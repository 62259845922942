import { useEffect, useState } from 'react';

import { UserProfileInputGroup } from './UserProfileInputGroup';
import UserProfileCard from '../../UserProfileCard/UserProfileCard';
import {
  useGetUserProfile,
  usePatchUserProfile,
} from '../../../Hooks/useApi/useAuthenticationApi';
import { CustomCircularLoading } from '../../CustomCircularLoading/CustomCircularLoading';
import { IUser, IUserProfileUpdate } from '../../../Models/UserModel';
import { defautUserValues } from './defaultUserValues';
import { useNavigate } from 'react-router-dom';
import * as S from './userProfileForms.styled';

export const IdentityForm = () => {
  const { userProfileData, userProfileDataIsLoading, userProfileError } =
    useGetUserProfile();
  const { updateUserProfile, isLoadingProfileUpdate, isUpdateError } =
    usePatchUserProfile();
  const navigate = useNavigate();

  const [{ first_name, last_name, email, phone_number }, setFormValues] =
    useState<IUser>(defautUserValues);

  // Sync state with userData once it arrives
  useEffect(() => {
    if (userProfileData) {
      setFormValues(userProfileData);
    }
  }, [userProfileData]);

  // Update formValues on input change
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const identityPayload = {
      ...userProfileData,
      first_name,
      last_name,
      phone_number,
    };
    // Add 2nd safeguard to avoid changing email
    delete identityPayload.email;
    updateUserProfile(identityPayload as IUserProfileUpdate);
  };

  if (userProfileDataIsLoading || isLoadingProfileUpdate) {
    return (
      <UserProfileCard>
        <CustomCircularLoading />;
      </UserProfileCard>
    );
  }

  return (
    <S.UserProfileFormContainer onSubmit={handleSubmit}>
      <UserProfileCard>
        {userProfileError && (
          <S.UserProfileErrorSpan>
            Error fetching user profile data
          </S.UserProfileErrorSpan>
        )}
        {isUpdateError && (
          <S.UserProfileErrorSpan>
            Error updating user profile data
          </S.UserProfileErrorSpan>
        )}
        <UserProfileInputGroup
          label="First Name"
          name="first_name"
          value={first_name || ''}
          handleInputChange={handleInputChange}
        />
        <UserProfileInputGroup
          label="Last Name"
          name="last_name"
          value={last_name || ''}
          handleInputChange={handleInputChange}
        />

        <UserProfileInputGroup
          label="Email"
          name="email"
          value={email || ''}
          handleInputChange={handleInputChange}
          disabled
        />
        <UserProfileInputGroup
          label="Phone Number"
          name="phone_number"
          value={phone_number || ''}
          handleInputChange={handleInputChange}
        />
        <S.UserProfileFormFooter>
          <S.UserProfileReturnButton onClick={() => navigate('/')}>
            Back to ChatPage
          </S.UserProfileReturnButton>
          <S.UserProfileButton type="submit">Save</S.UserProfileButton>
        </S.UserProfileFormFooter>
      </UserProfileCard>
    </S.UserProfileFormContainer>
  );
};
