import { Image } from '../../App/Styles/Image.styled';
import lb123 from '../../Assets/123loadboard.png';
import truckStop from '../../Assets/truckStop.png';

interface BoardIconProps {
  board?: string;
  url_123LoadBoard?: string; // Only for 123LoadBoard
}

const BoardIcon: React.FC<BoardIconProps> = ({ board, url_123LoadBoard }) => {
  if (!board) return null;

  const boardLower = board.toLowerCase();

  switch (boardLower) {
    case 'manifold':
      return null; // No icon for 'Manifold'
    case '123loadboard':
      return (
        <a
          href={url_123LoadBoard || '#'}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image src={lb123} height="2rem" width="2rem" />
        </a>
      );
    case 'truckstop':
      return <Image src={truckStop} height="2rem" width="2rem" />;
    default:
      return null; // Default case for unknown board values
  }
};

export default BoardIcon;
