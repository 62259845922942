/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useChatApi from '../../../Hooks/useApi/useChatApi';
import { PluginContext } from '../../../Context/Plugin/PluginContext';

const LoginRedirectPage = () => {
  const pluginContext = useContext(PluginContext);
  const navigate = useNavigate();
  const { login123LB } = useChatApi();
  const location = useLocation();

  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  useEffect(() => {
    console.log('start effect'); // Added from the mb-09-27-2024 branch
    (async () => {
      try {
        const value = queryParams.get('code'); // Retrieve the value of a specific query parameter
        console.log('login123LB Value', value);
        if (value === null) {
          console.log('value null');
          return;
        }
        const data = await login123LB(value);
        if (data === null) {
          console.log('data null');
          return;
        }
        console.log('login123LB data', data); // Added from the mb-09-27-2024 branch
        pluginContext?.setLB123Plugin(true);
        navigate('/');
      } catch (e) {
        console.error('Error during login redirect:', e); // Added error logging
      }
    })();
  }, []);

  return null;
};

export default LoginRedirectPage;
