import { Tooltip } from '@mui/material';
import styled from 'styled-components';

import FMCSA from '../../Assets/FMCSA.png';

export const FMCSATooltip = () => {
  return (
    <StyledTooltip title="FMCSA" placement="top">
      <ImageWrapper>
        <img src={FMCSA} alt="FMCSA Icon" />
      </ImageWrapper>
    </StyledTooltip>
  );
};

const StyledTooltip = styled(Tooltip)`
  position: absolute;
  right: 0;
  top: -8px;
  font-size: 12px !important;
  transform: translate(3px, -1px);
  color: ${({ theme }) => theme.alternativeWhite};
`;

const ImageWrapper = styled.div`
  width: 40px; /* Adjust to your desired size */
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
