import { MessageItem } from '../../../Components/Recycler/MessageRecycler';

export const addMessage = (
  setMessages: React.Dispatch<React.SetStateAction<MessageItem[]>>,
  baseMessage: Partial<MessageItem>
) => {
  setMessages((prevState) => [...prevState, { ...baseMessage } as MessageItem]);
};

export const handleNoProviders = (
  setMessages: React.Dispatch<React.SetStateAction<MessageItem[]>>
) => {
  console.error('User not authorized with any boards');
  addMessage(setMessages, {
    isUser: false,
    title:
      'You are not authorized with any providers. Please log in to a provider to get results.',
  });
};

export const handleNoResponse = (
  setMessages: React.Dispatch<React.SetStateAction<MessageItem[]>>,
  response: any
) => {
  console.error('Failed to fetch message response:', response);
  addMessage(setMessages, {
    isUser: false,
    title:
      'There was an issue retrieving your messages. Please try again later.',
  });
};

export const handleMultipleDataError = (
  setMessages: React.Dispatch<React.SetStateAction<MessageItem[]>>,
  responseData: any
) => {
  console.error(
    'Invalid data structure with Multiple Prompts modification:',
    responseData
  );
  addMessage(setMessages, {
    isUser: false,
    title: 'A response from the server could not be processed.',
  });
};

export const handleUnexpectedResultStructure = (
  setMessages: React.Dispatch<React.SetStateAction<MessageItem[]>>,
  result: any
) => {
  console.warn('Unexpected message structure:', result);
  addMessage(setMessages, {
    isUser: false,
    title: 'A response from the server could not be processed.',
  });
};
