import { KeyboardEvent } from 'react';
import { Image } from '../../../App/Styles/Image.styled';
import CircularProgress from '@mui/material/CircularProgress';
import { useMediaQuery } from '@mui/material';

import { Input, InputContainer, SendButton } from './ChatPage.styled';
import { ChatResetTooltip } from '../../../Components/Tooltips/ChatResetTooltip';

interface ChatPageInputProps {
  inputRef: React.MutableRefObject<HTMLInputElement | null>;
  handleKeyDown: (event: KeyboardEvent<HTMLInputElement>) => Promise<void>;
  inputText: string;
  setInputText: (value: React.SetStateAction<string>) => void;
  isProgress: boolean;
  onSendMessageClickListener: (query?: string) => Promise<void>;
  isMessages: boolean;
}

export const ChatPageInput: React.FC<ChatPageInputProps> = ({
  inputRef,
  handleKeyDown,
  inputText,
  setInputText,
  isProgress,
  onSendMessageClickListener,
  isMessages,
}) => {
  const matches = useMediaQuery('(max-width:1320px)');

  return (
    <InputContainer>
      <Input
        type="text"
        spellCheck={false}
        ref={inputRef}
        placeholder="Type your query here..."
        onKeyDown={(event) => inputText && handleKeyDown(event)}
        onChange={(event) => setInputText(() => event.target.value)}
      />
      {isProgress ? (
        <CircularProgress
          size={matches ? 40 : 50}
          color="warning"
          sx={{
            marginLeft: '10px',
          }}
        />
      ) : (
        <>
          <SendButton
            onClick={() => onSendMessageClickListener()}
            disabled={!inputText}
          >
            <Image
              src="https://www.svgrepo.com/show/520931/send-2.svg"
              width="3em"
              height="3em"
            />
          </SendButton>
          {isMessages && <ChatResetTooltip />}
        </>
      )}
    </InputContainer>
  );
};
