const BASE_URL = process.env.REACT_APP_SERVER_API_URL;
const BASE_SERVICE_URL = process.env.REACT_APP_BASE_SERVICE_URL;

const TOKEN_URL = `${BASE_URL}/token`;
const USERS_URL = `${BASE_URL}/users`;
const LOADS_URL = `${BASE_URL}/loads`;
const CHAT_URL = `${BASE_URL}/chat`;
const SCHEDULER_URL = `${BASE_SERVICE_URL}/scheduler`;

export const LOGIN_ENDPOINT = `${USERS_URL}/login`;
export const REFRESH_ENDPOINT = `${TOKEN_URL}/refresh`;

// LOADS
export const LB123_LOGIN_ENDPOINT = `${LOADS_URL}/lb123/login`;
export const LB123_ADD_TOKENS_ENDPOINT = `${LOADS_URL}/lb123/tokens`;
export const LB123_LOADS_ENDPOINT = `${LOADS_URL}/lb123/list`;
export const LB123_DETAILS_ENDPOINT = `${LOADS_URL}/lb123/details`;
export const TRUCKSTOP_DETAILS_ENDPOINT = `${LOADS_URL}/truckstop/details`;
export const FMCSA_DETAILS_ENDPOINT = `${LOADS_URL}/fmcsa/details`;
export const TRUCKSTOP_RATE_INSIGHTS_ENDPOINT = `${LOADS_URL}/truckstop/rate-insights`;
export const MULTIPLE_PROVIDER_DETAILS_ENDPOINT = `${LOADS_URL}/details`;

// CHAT
export const LOGIN_123LB_ENDPOINT = `${CHAT_URL}/123lb/login`;
export const LOGIN_TRUCKSTOP_ENDPOINT = `${CHAT_URL}/truckstop/login`;
export const CHECK_TRUCKSTOP_ENDPOINT = `${CHAT_URL}/truckstop/check`;
export const CHECK_123LB_ENDPOINT = `${CHAT_URL}/123lb/check`;
export const CHECK_MANIFOLD_ENDPOINT = `${CHAT_URL}/manifold/check`;
export const SEND_MESSAGE_ENDPOINT = `${CHAT_URL}/message`;
export const GET_CHAT_SETTINGS_ENDPOINT = `${CHAT_URL}/settings`;
export const UPDATE_CHAT_SETTINGS_ENDPOINT = `${CHAT_URL}/settings/update`;
export const TRUCKSTOP_RATINGS_ENDPOINT = `${CHAT_URL}/truckstop/login/rate-insights`;
export const GET_MANIFOLD_PROVIDERS_ENDPOINT = `${CHAT_URL}/manifold/providers`;

// USERS
export const REGISTER_ENDPOINT = `${USERS_URL}/register`;
export const SEND_EMAIL_ENDPOINT = `${USERS_URL}/send-email`;
export const VERIFY_EMAIL_ENDPOINT = `${USERS_URL}/email-verification`;
export const USER_INFO_ENDPOINT = `${USERS_URL}/details`;
export const RESET_REQUEST_ENDPOINT = `${USERS_URL}/reset/request`;
export const RESET_CHECK_ENDPOINT = `${USERS_URL}/reset/check`;
export const RESET_PASSWORD_ENDPOINT = `${USERS_URL}/reset/password`;

// SCHEDULER
export const GET_ALL_PROMPTS = `${SCHEDULER_URL}/prompts/list`;
export const DEACTIVATE_PROMPT = `${SCHEDULER_URL}/prompts/deactivate`;
export const CREATE_PROMPT = `${SCHEDULER_URL}/prompts/create`;
