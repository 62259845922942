import styled from "styled-components";

interface InputContainerProps {
  title: string;
  inputType: string;
  value: string | null;
  onTextChange: (value: string) => void;
  errorNotify?: boolean | undefined;
  options?: string[] | undefined;
  required?: boolean | undefined;
}

const InputContainer: React.FC<InputContainerProps> = ({
  title,
  inputType,
  value,
  options,
  onTextChange,
  errorNotify,
  required
}) => {
  return (
    <InputWrapper>
      <InputLabel>{title} {required && <RedText>*</RedText>}</InputLabel>
      {inputType === "radio" && options && (
        options.map((item, index) => (
          <RadioItem key={index}>
            <RadioInput
              id={item.toLowerCase()}
              type={inputType}
              value={item}
              name={title.toLowerCase()}
              onChange={e => onTextChange(e.target.value)}
              defaultChecked={index === 0}
            />
            <RadioLabel htmlFor={item.toLowerCase()}>{item}</RadioLabel>
          </RadioItem>
        ))
      )}
      {inputType === "textarea" &&
        <StyledTextarea cols={4} value={value || ''} error={errorNotify} onChange={e => onTextChange(e.target.value)} />}
      {inputType !== "textarea" && inputType !== "radio" &&
        <StyledInput type={inputType} value={value || ''} error={errorNotify} onChange={e => onTextChange(e.target.value)} />}
    </InputWrapper>
  );
}


export default InputContainer;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 0.2rem; /* Reduced gap */
`;

const InputLabel = styled.label`
  font-size: 22px;
  color: #C7C7C7;
  font-weight: lighter;
`;

const RadioLabel = styled.label`
  font-size: 1.3em;
  color: #C7C7C7;
  font-weight: lighter;
  margin: 0.3rem 0.4rem;
`;

const RadioInput = styled.input`
  margin-left: 1em;
`;

const RadioItem = styled.div`
`;

const StyledInput = styled.input<{ error: boolean | undefined }>`
  font-size: 1.2em; /* Make the input text larger */
  font-family: inherit;
  padding: 0.5em 0.5em;
  width: 100%;
  background-color: transparent;
  color: #C7C7C7;
  border: 1px solid rgba(199, 199, 199, 0.6); 
  border-radius: 10px;
  height: 3rem;
  outline: none; /* Remove default outline */
  margin-top: 0.2rem; /* Reduced margin-top */
  ${({ error }) => error !== undefined && error &&
    'border-color: red; box-shadow: 0 0 0 2px red;'
  }

  &:focus {
    border-color: white;
    box-shadow: 0 0 0 2px white; /* Add white shadow on focus */
  }
`;

const StyledTextarea = styled.textarea<{ error: boolean | undefined }>`
  font-size: 1.2em; /* Make the input text larger */
  font-family: inherit;
  padding: 0.5em 0.5em;
  width: 100%;
  background-color: transparent;
  color: #C7C7C7;
  border: 1px solid rgba(199, 199, 199, 0.6); 
  border-radius: 10px;
  height: 6rem;
  max-height: 25rem;
  outline: none; /* Remove default outline */
  margin-top: 0.2rem; /* Reduced margin-top */
  resize: vertical; /* Only resize vertically not horizontal */
  ${({ error }) => error !== undefined && error &&
    'border-color: red; box-shadow: 0 0 0 2px red;'
  }

  &:focus {
    border-color: white;
    box-shadow: 0 0 0 2px white; /* Add white shadow on focus */
  }
`;

const RedText = styled.span`
  color: red;
`
