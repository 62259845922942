import React from 'react';
import { Fade } from 'react-awesome-reveal';
import { useMediaQuery } from '@mui/material';

import { HelpBoxType } from '../../../App/Types/helpBox';
import HelpBox from '../../../Components/HelpBox/HelpBox';
import { Title } from '../../../App/Styles/Typography.styled';
import {
  GreetingsHeader,
  GreetingTextContainer,
  HelpBoxList,
  ParagraphContainer,
  ParagraphContainer2,
} from './ChatPage.styled';
import {
  GREETINGS_PARAGRAPH_1,
  GREETINGS_PARAGRAPH_2,
} from '../../../Other/constants';
import { useGetUserProfile } from '../../../Hooks/useApi/useAuthenticationApi';

interface GreetingSectionProps {
  helpList: HelpBoxType[];
  onHelpBoxClickListener: (title: string) => void;
}

const GreetingSection: React.FC<GreetingSectionProps> = ({
  helpList,
  onHelpBoxClickListener,
}) => {
  const { userProfileData } = useGetUserProfile();
  const lessThan768px = useMediaQuery('(max-width:768px)');

  const userName = userProfileData?.first_name || 'there';
  return (
    <>
      <Fade
        triggerOnce
        delay={150}
        style={{ gridRow: `${lessThan768px ? '5' : '4'}`, gridGap: '1rem' }}
      >
        <GreetingTextContainer>
          <GreetingsHeader style={{ margin: '10px 0px', fontSize: '25px' }}>
            <Title data-testid="greeting-text">{`Hello ${userName}!`}</Title>
          </GreetingsHeader>
          <ParagraphContainer>
            <Title>{GREETINGS_PARAGRAPH_1}</Title>
          </ParagraphContainer>
          <ParagraphContainer2 style={{ margin: '10px 0px' }}>
            <Title>{GREETINGS_PARAGRAPH_2}</Title>
          </ParagraphContainer2>
        </GreetingTextContainer>
      </Fade>

      <Fade triggerOnce delay={400} style={{ gridRow: '6' }}>
        <HelpBoxList>
          {helpList.map((item, index) => (
            <HelpBox
              key={index}
              item={item}
              onClick={() => onHelpBoxClickListener(item.title)}
            />
          ))}
        </HelpBoxList>
      </Fade>
    </>
  );
};

export default GreetingSection;
