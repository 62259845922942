import { useState } from "react";
import styled from "styled-components";
import ContactCard from "./ContactCard";
import NavigationLoginLayout from "../../Layouts/Navigation/NavigationLogInLayout";
import BottombarLayout from "../../Layouts/Bottombar/BottombarLayout";
import { Popup } from "../../Components/Popup/Popup";

const ContactPage = () => {
  const [successMessage, setSuccess] = useState<string>("");

  return (
    <PageContainer>
      <NavigationLoginLayout />
      {successMessage && <Popup title="Success!" subtitle={successMessage} handleExit={() => setSuccess("")} />}
      <CardContainer>
        <ContactCard setSuccess={setSuccess}/>
      </CardContainer>
      <BottombarLayout />
    </PageContainer>
  )
}

export default ContactPage

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;
    justify-content: space-between;
    height: 100vh;
`;

const CardContainer = styled.div`
    padding-left: 7%;
`;
