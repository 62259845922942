import {
  CenterContainer,
  ColumnRightTitle,
  Container,
  FlexColumnRightLayout,
} from './RightbarLayout.styled';
import { RightBarProviders } from './RightBarProviders';

const RightbarLayout = () => {
  return (
    <Container>
      <CenterContainer>
        <FlexColumnRightLayout justify="space-between" alignItems="center">
          <ColumnRightTitle bold={false}>Manage Providers</ColumnRightTitle>
          <RightBarProviders />
          {/* Truckstop Rate Insights Plugin */}
          {/* <PluginContainerButton
              onClick={() => (window.location.href = getTruckstopRateInsightsLoginURL())}
              $enabled={true}
            >
              <ResponsiveImage src={rateInsightsProductLogo} width="2rem" height="2rem" />
              <FlexColumn justify="center" gap="0.1rem" alignItems="center">
                <ResponsiveParagraph bold={true}>Truckstop Rate Insights</ResponsiveParagraph>
                <ResponsiveText>use provider</ResponsiveText>
              </FlexColumn>
              {pluginContext!.truckstopRateInsightsPlugin ? <OnlineLamp /> : <OfflineLamp />}
            </PluginContainerButton> */}
        </FlexColumnRightLayout>
      </CenterContainer>
    </Container>
  );
};

export default RightbarLayout;
