import React, { useState } from 'react';
import styled from 'styled-components';
import { HeadTitle, Paragraph } from '../../App/Styles/Typography.styled';
import HelmetView from "../../Components/Helmet/HelmetView";
import { VectorImage } from '../../App/Styles/Image.styled';
import closeSvg from "../../Assets/close.svg";
import InputContainer from '../../Components/InputContainer/InputContainer';
import useEmail from "../../Hooks/useApi/useEmail";

interface SignUpCardProps {
  setSuccess: (message: string) => void;
}


const SignUpCard: React.FC<SignUpCardProps> = ({ setSuccess }) => {
  const { sendEmail } = useEmail();
  const [inputNotify, setInputNotify] = useState<number>(-1);
  const [firstName, setFirstName] = useState<string | null>(null);
  const [lastName, setLastName] = useState<string | null>(null);
  const [email, setEmail] = useState<string | null>(null);
  const [message, setMessage] = useState<string>('');
  const [company, setCompany] = useState<string | null>('')
  const [dotNumber, setDotNumber] = useState<string | null>('')
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(false);

  const handleMessageSend = async ()  => {
    if (!firstName) {
      setErrorMessage("Please enter your first name");
      return setInputNotify(0);
    }
    if (!lastName) {
      setErrorMessage("Please enter your last name");
      return setInputNotify(1);
    }
    if (!email) {
      setErrorMessage("Please enter your email");
      return setInputNotify(2);
    }
    if (!message) {
      setErrorMessage("Please specify a message");
      return setInputNotify(3);
    }

    setLoading(true);
    const errMsg = await sendEmail({ firstName, lastName, message, email, company, dotNumber });
    if (errMsg.length > 0) {
      // NOTE: Moderately hacky way please fix this.
      if (/not.*valid.*email/g.test(errMsg)) {
        setInputNotify(2);
      }
      setErrorMessage(errMsg);
      setLoading(false); // Set loading false before return
      return;
    }
    setSuccess("Thank you for contacting us! We will reach out to you soon.")
    setLoading(false);
  }

  const handleFormFocus = () => {
    if (inputNotify > 0) {
      setInputNotify(-1);
    }
  }

  return (
    <CardContainer>
      <HelmetView title="Contact - Tarmac-AI" />
      <CardContent>
        <StyledHeadTitle bold={true}>Contact Us</StyledHeadTitle>
        <FormContainer onFocus={handleFormFocus}>
          <DoubleInputContainer>
            <InputContainer
              title="First Name"
              inputType="text"
              value={firstName}
              onTextChange={setFirstName}
              errorNotify={inputNotify === 0}
              required
            />
            <InputContainer

              title="Last Name"
              inputType="text"
              value={lastName}
              onTextChange={setLastName}
              errorNotify={inputNotify === 1}
              required
            />
          </DoubleInputContainer>
          <InputContainer title="Email"
            inputType="email"
            value={email}
            onTextChange={setEmail}
            errorNotify={inputNotify === 2}
            required
          />
          <DoubleInputContainer>
            <InputContainer
              title="Company"
              inputType="text"
              value={company} onTextChange={setCompany}
            />
            <InputContainer
              title="DOT Number"
              inputType="text"
              value={dotNumber}
              onTextChange={setDotNumber}
            />
          </DoubleInputContainer>
          <InputContainer
            title="Message"
            inputType="textarea"
            value={message}
            onTextChange={setMessage}
            errorNotify={inputNotify === 3}
            required
          />
          {errorMessage && (
            <ErrorMessageContainer>
              <HiddenButton onClick={() => setErrorMessage("")}>
                <VectorImage src={closeSvg} height="100%" margin='0' />
              </HiddenButton>
              <Paragraph>{errorMessage}</Paragraph>
            </ErrorMessageContainer>
          )}
          <SubmitButton isLoading={isLoading} onClick={handleMessageSend}>
            <ButtonText>Send Message</ButtonText>
          </SubmitButton>
        </FormContainer>
      </CardContent>
    </CardContainer>
  );
};

export default SignUpCard;

const CardContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
    padding: 3rem;
    max-width: 600px;
    width: 100%;
    border-bottom: 1px solid rgba(199, 199, 199, 0.3); 
    border-right: 1px solid rgba(199, 199, 199, 0.3);
    border-top: none;
    background-color: rgba(0, 0, 0, 0.3); 
`;

const CardContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
`;

const DoubleInputContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 1rem; 
`;

const ErrorMessageContainer = styled.div`
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding: 1rem;
    margin-bottom: 1.5rem;
    width: 100%;
    border: 1px solid rgba(199, 199, 199, 0.3);
`;

const SubmitButton = styled.button<{ isLoading: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;
    background-color: #AD8449;
    border-radius: 10px;
    width: 100%;
    padding: 1rem;
    cursor: pointer;
    opacity: ${({ isLoading }) => (isLoading ? 0.7 : 1)};
    pointer-events: ${({ isLoading }) => (isLoading ? 'none' : 'auto')};
    white-space: nowrap;
    font-size: 1em;

    &:hover {
        opacity: 0.8;
    }
`;
const HiddenButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    margin-right: 1rem;
`;

const ButtonText = styled(Paragraph)`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; 
    color: #000000;
    font-size: 1.5em; 
    font-weight: bold;
`;

const StyledHeadTitle = styled(HeadTitle)`
    margin-bottom: 2.5rem;
    align-self: flex-start;
    color: white;
    font-weight: bolder;
`;

