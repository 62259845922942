import styled from 'styled-components';

interface SelectGroupProps {
  label: string;
  name: string;
  value: string;
  options: string[];
  handleSelectChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  disabled?: boolean;
}

export const UserProfileSelectGroup: React.FC<SelectGroupProps> = ({
  label,
  name,
  value,
  options,
  handleSelectChange,
  disabled,
}) => {
  return (
    <FormGroup>
      <Label htmlFor={name}>{label}</Label>
      <Select
        name={name}
        id={name}
        value={value}
        onChange={handleSelectChange}
        disabled={disabled}
      >
        <option value="">Select {label}</option>
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </Select>
    </FormGroup>
  );
};

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2px;
  margin-bottom: 10px;
  width: 100%;
`;

const Label = styled.label`
  margin-bottom: 8px;
  color: ${({ theme }) => theme.detailsText};
`;

const Select = styled.select`
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  outline: none;
  color: black;
  height: 36px;
  background-color: ${({ disabled, theme }) =>
    disabled ? theme.settingsDisabledBg : theme.settingsEnabledBg};
  :hover {
    cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  }
`;
