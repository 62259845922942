// Define the 123Loadboard Authorization URL
const REACT_APP_123LB_AUTH_URL =
  process.env.REACT_APP_123LB_AUTH_URL ||
  'https://api.dev.123loadboard.com/authorize';

export const get123LoadboardLoginURL = () => {
  const data = {
    client_id: process.env.REACT_APP_123LB_CLIENT_ID!,
    scope: 'loadsearching ratecheck',
    resource: '0',
    response_type: 'code',
    state: '0',
    redirect_uri: process.env.REACT_APP_123LB_REDIRECT_URL!,
  };
  const params = new URLSearchParams(data).toString();
  return `${REACT_APP_123LB_AUTH_URL}?${params}`;
};

// // Truckstop Rate Insights Login URL function
// const getTruckstopRateInsightsLoginURL = () => {
//   const clientId = process.env.REACT_APP_TRUCKSTOP_RATINGS_CLIENT_ID;
//   const redirectUri = process.env.REACT_APP_TRUCKSTOP_RATINGS_REDIRECT_URL;

//   if (!clientId || !redirectUri) {
//     console.error(
//       'Environment variables for Truckstop Rate Insights are missing.'
//     );
//     return '';
//   }

//   const data = {
//     client_id: clientId,
//     scope: 'truckstop',
//     response_type: 'code',
//     state: 'abcd',
//     redirect_uri: redirectUri,
//   };

//   const params = new URLSearchParams(data as Record<string, string>).toString();
//   return `https://api.truckstop.com/auth/authorize?${params}`;
// };
