import Alert from '@mui/material/Alert';

interface MuiChatPageAlertProps {
  scheduledPromptMissingProviders: string;
  setScheduledPromptMissingProviders: (value: string) => void;
}

export const MuiChatPageAlert: React.FC<MuiChatPageAlertProps> = ({
  scheduledPromptMissingProviders,
  setScheduledPromptMissingProviders,
}) => {
  if (!scheduledPromptMissingProviders) return null;

  return (
    <Alert
      sx={{
        position: 'absolute',
        top: '-70px',
        marginTop: '35px',
        background: 'transparent',
        color: '#9b9b9b',
      }}
      severity="warning"
      onClose={() => setScheduledPromptMissingProviders('')}
    >
      {scheduledPromptMissingProviders}
    </Alert>
  );
};
